<template>
        <form class="form-body col-lg-6" @submit.prevent>
                <div class="row" >
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="name">{{$t("ContactUs.Form.Name")}}</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="name" class="input-size input-normal" @keyup="checkIfBlank('name')" @focusout="checkIfBlank('name')" @focusin="checkIfBlank('name')"  :value.sync="$v.form.name.$model" required autocomplete="off" :isValid="checkIfValid('name')" :invalidFeedback="flag_alertName"/>
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="name" @touchend="checkIfBlank('name')" @touchcancel="checkIfBlank('name')" @touchstart="checkIfBlank('name')" :value.sync="$v.form.name.$model" required autocomplete="off" :isValid="checkIfValid('name')" :invalidFeedback="flag_alertName"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="phone">{{$t("ContactUs.Form.Tel")}}</label>
                        <CInput class="input-size" :value.sync="$v.form.phone.$model" type="text" autocomplete="off"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="email">{{$t("ContactUs.Form.Email")}}</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="mail" class="input-size input-normal" @keyup="checkIfBlank('mail')"  @focusout="checkIfBlank('mail')" @focusin="checkIfBlank('mail')"  :value.sync="$v.form.mail.$model" required autocomplete="off" :isValid="checkIfValid('mail')" :invalidFeedback="flag_alertEmail"/>
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="mail" @touchend="checkIfBlank('mail')"  @touchcancel="checkIfBlank('mail')" @touchstart="checkIfBlank('mail')" :value.sync="$v.form.mail.$model" required autocomplete="off" :isValid="checkIfValid('mail')" :invalidFeedback="flag_alertEmail"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="company">{{$t("ContactUs.Form.Company")}}</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="company" class="input-size input-normal" @keyup="checkIfBlank('company')"  @focusout="checkIfBlank('company')" @focusin="checkIfBlank('company')"   :value.sync="$v.form.company.$model" required autocomplete="off" :isValid="checkIfValid('company')" :invalidFeedback="flag_alertCompany"/>
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="company" @touchend="checkIfBlank('company')"  @touchcancel="checkIfBlank('company')" @touchstart="checkIfBlank('company')" :value.sync="$v.form.company.$model" required autocomplete="off" :isValid="checkIfValid('company')" :invalidFeedback="flag_alertCompany"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="jobTitle">{{$t("ContactUs.Form.JobTitle")}}</label>
                        <CInput class="input-size" :value.sync="$v.form.jobTitle.$model" type="text" autocomplete="off"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="request">{{$t("ContactUs.Form.Request")}}</label>
                        <CInput class="input-size" :value.sync="$v.form.request.$model" type="text" autocomplete="off"/>
                    </div>
                    <div class="col-lg-12 btn-container">
                        <div v-if="flag_formReminder" style="display:block;text-align:center">
                            <span style="color:red">{{$t("ContactUs.Form.Error_Msg")}}</span>
                        </div>
                        <button class="btn-primary btn btn-contact" @click="sendEmail" :disabled="!flag_correctForm">{{$t("ContactUs.Submit")}}</button>
                    </div>
                </div>
            </form>
</template>

<script>
import emailjs from 'emailjs-com';
import { validationMixin } from 'vuelidate'
import { required} from "vuelidate/lib/validators"

const defaultState = () => {
    return {
        form: {
            name:"",
            phone:"",
            mail: "",
            company: "",
            jobTitle: "",
            request: ""
        },
        flag_alertName: "請填寫姓名",
        flag_alertEmail: "請填寫Email",
        flag_alertCompany: "請填寫公司名稱",
        flag_correctForm: false,
        flag_isMobile: false,
        flag_formReminder: false
    }
}

export default {
    name: 'AdsContactForm',

    data() {
        return Object.assign({}, defaultState())
    },

    computed: {

        
    },
    watch: {
        "form.name" : function() {
            if(this.form.name.length==0){
                this.flag_correctForm = false;
            } else {
                this.flag_correctForm = true;
            }
        },
        "form.mail" : function() {
            if(this.form.mail.length==0){
                this.flag_correctForm = false;
            } else {
                this.flag_correctForm = true;
            }
        },
        "form.company" : function() {
            if(this.form.company.length==0){
                this.flag_correctForm = false;
            } else {
                this.flag_correctForm = true;
            }
        } 
    },

    created() {
    },
    mounted(){
        this.detectMob();
    },
    mixins: [validationMixin],
    validations: {
        form: {
            name: { required },
            phone : {},
            mail:{ required },
            company: { required },
            jobTitle: {},
            request: {}
        },
        flag_correctForm: false
    },
    methods: {
        detectMob() { 
            if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)|| navigator.userAgent.match(/iPhone/i)|| navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)|| navigator.userAgent.match(/Windows Phone/i)
            ){
                this.flag_isMobile = true;
            }else {
                this.flag_isMobile = false;
            }
        },
        sendEmail(){

            const me = this;

            const contactEmail = {
                name: me.form.name,
                phone:me.form.phone,
                mail: me.form.mail,
                company: me.form.company,
                jobTitle: me.form.jobTitle,
                request: me.form.request
            }

            emailjs.send('default_service','template_qsb4hca',contactEmail,'Nrk3C1168nDCDbQ88')
            .then((res)=>{
                const me = this;
                if(res.text=="OK"){
                    me.flag_formReminder = false;
                    me.$parent.flag_currentStep = 1;
                }else{
                    me.flag_formReminder = true;
                    me.form = {
                        name:"",
                        phone:"",
                        mail: "",
                        company: "",
                        jobTitle: "",
                        request: ""
                    };
                    me.flag_correctForm = false;
                    return me.flag_correctForm
                }
            }).catch((err)=>{
                console.error(err)
            })
        },
        checkIfValid(fieldName) {
				const field = this.$v.form[fieldName]
				if (!field.$dirty) {
					return null
				}
				return !(field.$invalid || field.$model === '')
        },
        checkData(){
            let me = this;
            if(me.form.name.length!=0 && me.form.mail.length!=0 && me.form.company.length!=0 ){
                me.flag_correctForm = true;
            }else{
                me.flag_correctForm = false;
            }
        },
        checkIfBlank(fieldname){
            const me = this;
            switch(fieldname){
                case "name" : {
                    let inputElement = document.getElementById('name');
                    
                    if (me.form.name.replace(/\s/g, "").length == 0) {
                        inputElement.classList.add('input-alert');
                        inputElement.classList.remove('input-normal');
                        inputElement.style.border = '2px solid #dc3545';
                        me.flag_correctForm = false;
                        return me.flag_correctForm
                    }
                    else {
                        inputElement.classList.remove('input-alert');
                        inputElement.classList.add('input-normal');
                        inputElement.style.border = '2px solid #80bdff';
                        me.flag_correctForm = true;
                        me.checkData()
                        return me.flag_correctForm
                    }
                    
                }
                case "mail" : {
                    let inputElement2 = document.getElementById('mail');
                    if (me.form.mail.replace(/\s/g, "").length == 0) {
                        inputElement2.classList.add('input-alert');
                        inputElement2.classList.remove('input-normal');
                        inputElement2.style.border = '2px solid #dc3545';
                        me.flag_correctForm = false;
                        return me.flag_correctForm
                    }
                    else {
                        inputElement2.classList.remove('input-alert');
                        inputElement2.classList.add('input-normal');
                        inputElement2.style.border = '2px solid #80bdff';
                        me.flag_correctForm = true;
                        me.checkData()
                        return me.flag_correctForm
                    }
                }
                case "company" : {
                    let inputElement2 = document.getElementById('company');
                    if (me.form.company.replace(/\s/g, "").length == 0) {
                        inputElement2.classList.add('input-alert');
                        inputElement2.classList.remove('input-normal');
                        inputElement2.style.border = '2px solid #dc3545';
                        me.flag_correctForm = false;
                        return me.flag_correctForm
                    }
                    else {
                        inputElement2.classList.remove('input-alert');
                        inputElement2.classList.add('input-normal');
                        inputElement2.style.border = '2px solid #80bdff';
                        me.flag_correctForm = true;
                        me.checkData()
                        return me.flag_correctForm
                    }
                }
                
            }
        },
    }
}
</script>

<style lang="scss">

.form-body{
    background-color: #ffffff;
    font-weight: 400;
    font-size: 24px;
    margin-top: 60px;
    padding: 40px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    border: solid 1px #ebebeb;
}

.input-size{
    max-width: 400px;
}
.input-normal:focus{
		outline: none !important;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255,0.25);
		border-radius: 0.25rem
}
.input-alert:focus{
    outline: none !important;
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69,0.25);
    border-radius: 0.25rem
}
.btn-container{
    display:block;
    margin-top:30px;
    text-align:center
}

.btn-contact{
    height:60px;
    width:300px;
    font-size:33px;
    border-radius:0.5rem;
}

@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 950px) {
    .input-size{
        max-width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .input-size{
        max-width: unset
    }

}

</style>