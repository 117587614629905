<template>
    <div class="card-body">
        <div class="btn-layout">
            <router-link to="/">
                <button class="btn-primary btn btn-contact">{{ $t("ContactUs.BackToHome")}}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactSuccess'
}
</script>

<style lang="scss">
.card-body{
    background-color: #ffffff;
    font-weight: 400;
    font-size: 24px;
    margin-top: 60px;
    padding: 40px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    border: solid 1px #ebebeb;
}


.btn-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.btn-contact{
    height:60px;
    width:300px;
    font-size:33px;
    border-radius:0.5rem;
}

</style>